import { useEffect, useState } from "react"


export default function Event(props) {
    const title = props.event.Title
    const image = props.event.img
    const body = props.event.Body
    const date = props.event.Date
    const time = props.event.Time


    const [startDate, setStartDate] = useState()

    useEffect(() => {

        setStartDate(`${new Date(props.event.Date+"T00:00:00.000-05:00").toLocaleDateString("en-US", { month: "long", day:"numeric", year: "numeric",timeZone: "America/Chicago" })} ${props.event.Time}`)

    }, [props.event])

    return (
        <div className='row py-2'>
            <div className='col-lg-4 py-3'>
                {(String(props.event.img).length === 0) ? " " : <img className='rounded border border-3' src={image} style={{ objectFit: "cover", width: "100%", maxWidth: "320px" }} alt="Event" />}
            </div>
            <div className='col-lg-8 py-3'>
                <h3>
                    {title} ({startDate})
                </h3>
                <p>
                    <em>{body}</em>
                    <br />
                </p>
            </div>
        </div>
    )
}