import { Link } from "react-router-dom"

export default function Report(props) {
    const year = props.event.Year
    const title = props.event.Title
    const link = props.event.Link

/*
FOR REPORTS.JSON:
{
        "Year":"2024",
        "Title":"May Report",
        "Link":"/assets/Reports/Parker County Monthly - May 2024.pdf",
        "id":1
    }

*/

    return (
        <tr>
            <td>
            <Link style={{textDecoration:"none", color:"black"}} to={link} target="_blank">{year} ◦ {title}</Link>
            </td>
        </tr>
    )
}