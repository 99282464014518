import { Link } from "react-router-dom"
import { useLocation } from 'react-router-dom';
import { Dropdown, NavDropdown } from "react-bootstrap"
import navImg from '../assets/Color Seal.png'

export default function Navbar() {

    const location = useLocation();

    const { pathname } = location;

    const splitLocation = pathname.split("/");

    return (
        <div>
            <nav className="navbar navbar-expand-lg navbar-dark" id="navbar" style={{ backgroundColor: "#124c7c" }}>
                <div className="container mb-2">
                    <a className="navbar-brand"><img src={navImg}
                        style={{ height: "70px" }} alt="Parker County" /></a>
                    <button className="navbar-toggler collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse"> <span className="navbar-toggler-icon"></span> </button>
                    <div className="collapse navbar-collapse" id="navbarCollapse">
                        <ul className="navbar-nav fw-bold" style={{ fontSize: "20px" }}>
                            <li className="nav-item mx-1"> <Link className="nav-link" id={(splitLocation[1] === "" ? "active" : "")} to="/" >Home</Link></li>
                            <li className="nav-item mx-1"> <Link className="nav-link" id={(splitLocation[1] === "events" ? "active" : "")} to="/events">Events</Link></li>
                            <li className="nav-item mx-1"> <Link className="nav-link" id={(splitLocation[1] === "projects" || splitLocation[1] === "viewprojects" ? "active" : "")} to="/projects">Projects</Link></li>
                            <li className="nav-item mx-1"> <Link className="nav-link" id={(splitLocation[1] === "reports" ? "active" : "")} to="/reports">Reports</Link></li>
                        </ul>
                    </div>
                </div>
            </nav>
        </div>
    )
}