import img from '../assets/Banners/Banner - 006.jpg'
import file from '../assets/Public-Comment-Responses.pdf'

export default function Byron() {
    return (
        <div>
            <main className='flex-shrink-0 modular min-vh-100'>
                <img src={img} className="d-block w-100" style={{ objectFit: "cover", height: "450px" }} alt="Home" />
                <div className="container py-4">
                    <div className='row text-start mx-5 mt-4'>

                        <h2>Byron Farmstead Public Meeting Information</h2>
                        
                        <p>
                            <em>
                                Parker County proposes to construct the eastern segment of the Weatherford Loop along an alignment that would have an adverse effect on the Byron Farmstead, which is listed in the National Register of Historic Places. In accordance with Section 106 of the National Historic Preservation Act, Parker County is considering measures to avoid, minimize, and mitigate the adverse effect. As part of the Section 106 process required to comply with the U.S. Army Corps of Engineers, Fort Worth District, Regulatory Division, the County held a public meeting in February 2020. The County has carefully considered the comments made by the public at the meeting and during the comment period that followed. The link below provides the comments and County responses.
                            </em>
                        </p>

                        <a href={file} target="_blank">Public Comment Responses</a>

                    </div>
                </div>
            </main>
        </div>
    )
}