import { React, useState, useEffect } from 'react'
import ReportList from "../components/Reports.json";
import Report from '../components/Report'
import img from '../assets/Banners/Banner - 002.jpg'

export default function MonthlyReport() {

    const [totallistList, settotalListList] = useState([]);

    useEffect(() => {

        ReportList.sort((a, b) => {
            return (a.id) - (b.id);
        });

        var totallist = [];

        ReportList.forEach(e => {
            totallist.push(<Report event={e} key={e.id} />)
        });

        settotalListList(totallist);


    }, [])


    return (
        <div>
            <main className='flex-shrink-0 modular min-vh-100'>
                <img src={img} className="d-block w-100" style={{ objectFit: "cover", height: "450px" }} alt="Home" />
                <div className="container py-4">
                    <h1 className='mb-3'>Commissioners Court Reports</h1>

                    <table className="table table-striped table-hover mx-auto">
                        <tbody>
                            {(totallistList.length === 0) ? <h3 className="text-center py-2" style={{color:"grey"}}><em>No Reports Available</em></h3> : totallistList}
                        </tbody>
                    </table>


                </div>
            </main>
        </div>
    )
}