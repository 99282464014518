import React, { createRef, useEffect, useState } from 'react'
import { useParams, useLocation, Link } from 'react-router-dom'
import Projects from '../components/Projects.json'
import Spinner from '../components/Spinner'

export default function ViewProjects(props) {

    const { id } = useParams();
    const [precinct, setPrecinct] = useState('')
    const [precinct2, setPrecinct2] = useState('')
    const [number, setNumber] = useState('')
    const [title, setTitle] = useState('')
    const [funding, setFunding] = useState('')
    const [desc, setDesc] = useState('')
    const [filePath, setFilePath] = useState('')
    const [error, setError] = useState(false)
    const [loading, setLoading] = useState(true)
    const ref = createRef();
    const { pathname } = useLocation();
    const path = pathname.split("/")

    useEffect(() => {
        var data = null;

        for (let i = 0; i < Projects.length; i++) {
            if (id == Projects[i].id) {
                data = Projects[i]
            }
        }
        if (data == null) {
            setError(true)
        }


        setPrecinct(data.precinct)
        setNumber(data.number)
        setTitle(data.title)
        setFunding(data.funding)
        setDesc(data.desc)
        setFilePath(data.filePath)

        if (String(data.precinct2).length <= 5) {
            var prec = "& " + data.precinct2
            setPrecinct2(prec)
        } else {
            setPrecinct2("")
        }

        setLoading(false)

    }, [id]);


    if (path[1].toLowerCase() === 'viewprojects') {
        if (isNaN(path[2])) return (window.location.href = "/NotFound")
        const projId = parseInt(path[2])
        if (projId < 1) return (window.location.href = "/NotFound")
    }

    if (loading) {
        return (<main className='flex-shrink-0 modular min-vh-100'><Spinner padding={5} /></main>)
    }

    if (error) {
        return (<main className='flex-shrink-0 modular min-vh-100'><div>Page Not Found</div></main>)
    }

    return (
        <div>
            <main className='flex-shrink-0 modular min-vh-100'>
                <div className="container py-4 text-center">
                    <div ref={ref} id='project' className="row my-4 text-start ">
                        <div className='col-lg-6'>
                            <h5>Project {number}</h5>
                            <h1 className='mb-3' style={{ color: "#284E5F" }}> {title}</h1>

                            <h4>Precinct {precinct} {precinct2}</h4>
                            <p className='mt-4' style={{ whiteSpace: 'pre-wrap' }}>{desc}</p>

                        </div>
                        <div className='col-lg-6'>
                            <div className='card border-0'>
                                <div className='card-body'>
                                    {(String(filePath).length === 0) ? " " :
                                        <a href={filePath}>
                                            <img style={{ objectFit: "cover", width: "100%" }} alt="project" src={filePath} />
                                        </a>}
                                </div>
                            </div>
                        </div>
                    </div>
                    <Link to="/projects" style={{ textDecoration: "none" }}>
                        <div className='btn btn-outline-secondary btn-sm'>Back to Projects</div>
                    </Link>
                </div>

            </main>
        </div>
    )
}