import img from '../assets/Banners/Banner - 001.jpg'
import '../App.css'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendar, faFolderOpen, faPersonChalkboard } from '@fortawesome/free-solid-svg-icons'
import { faCalendar as farCalendar, faFolderOpen as farFolderOpen } from '@fortawesome/free-regular-svg-icons'
import Video1 from '../assets/Videos/Parker County Bond Compressed.mp4'
import placeholder from '../assets/MicrosoftTeams-image.png'

export default function Home() {

    const calendaricn = <FontAwesomeIcon icon={farCalendar} fontSize={"85px"} color='#124c7c' />
    const foldericn = <FontAwesomeIcon icon={farFolderOpen} fontSize={"85px"} color='#124c7c' />
    const charticn = <FontAwesomeIcon icon={faPersonChalkboard} fontSize={"85px"} color='#124c7c' />

    return (
        <div>
            <main className='flex-shrink-0 modular min-vh-100'>
                <img src={img} className="d-block w-100" style={{ objectFit: "cover", height: "450px" }} alt="Home" />
                <div className="container py-4">
                    <div className='row mx-5 mt-4'>
                        <h1 className='mb-3'> 2023 Parker County Transportation Bond Program</h1>

                        <div className='mb-4'>
                            <div className="embed-responsive">
                            </div>

                        </div>

                        <div>

                            <p>
                            The purpose of the Parker County Transportation Bond Program is to meet critical transportation infrastructure needs across Parker County through the financing of strategically significant projects.  The population in Parker County grew by over 34% from 2010-2022 and is currently growing at a rate of just under 5%.  To keep up with the growth a $130M bond program consisting of 32 projects was proposed.  Voters approved this bond at the November 7, 2023 election.  Through this bond program the County will be able to not only leverage additional state and/or federal funding as it becomes available but also leverage partnerships with surrounding communities to provide strategic projects for their area.
                            <br/>
                            <br/>
                            </p>

                            <div className='row p-4 text-center'>
                                <div className='col-lg-4'>
                                    <Link to='/events' style={{ textDecoration: "none", color: "#124c7c" }}>
                                        {calendaricn}
                                        <h5 className='my-2'>Events</h5>
                                    </Link>
                                </div>
                                <div className='col-lg-4'>
                                    <Link to='/projects' style={{ textDecoration: "none", color: "#124c7c" }}>
                                        {foldericn}
                                        <h5 className='my-2'>Projects</h5>
                                    </Link>
                                </div>
                                <div className='col-lg-4'>
                                    <Link to='/Reports' style={{ textDecoration: "none", color: "#124c7c" }}>
                                        {charticn}
                                        <h5 className='my-2'>Reports</h5>
                                    </Link>
                                </div>
                            </div>

                        </div>
                        <div className='col-lg-4'>

                        </div>
                    </div>
                </div >
            </main >
        </div >
    )
}