import { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'

export default function ProjectTable(props) {

    const precinct = props.precinct
    const title = props.title
    const number = props.number
    const precinct2 = props.precinct2
    const id = props.id
    const navigate = useNavigate();

    const [prec2, setPrec2] = useState("");

    useEffect(
        () => {
            var legit =
                (String(precinct2).length > 1 ? "" : "& " + precinct2)

            setPrec2(legit)
        }, [])

    return (
        <tr onClick={() => navigate("/viewprojects/" + id)}>
            <td className="ps-4">{number}</td>
            <td className="ps-4">{title}</td>
            <td className="ps-4"> Precinct {precinct} {prec2} </td>
        </tr>
    )
}