export default function Footer(){
    return(
        <div style={{backgroundColor:"#0B3250"}}>
            <footer className="container py-4 mt-5">
                <div className='row text-light'>
                    <div className='col-lg-9 col-xs-9 text-start'>
                        <p>© Copyright 2023</p>
                    </div>
                </div>
            </footer>
        </div>
    )
}