import React, { createRef, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import ProjectsData from '../components/Projects.json'
import ProjectListItem from '../components/ProjectTable'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretUp, faCaretDown } from '@fortawesome/free-solid-svg-icons'

export default function Projects() {
    const [projectLinks, setProjectLinks] = useState([])
    const [sortBy, setSortBy] = useState(['Name', false])
    const [projectMap, setProjectMap] = useState('')
    const [projects, setProjects] = useState('')

    const arrowUp = <FontAwesomeIcon icon={faCaretUp} color='#124c7c' className='float-end' />
    const arrowDown = <FontAwesomeIcon icon={faCaretDown} color='#124c7c' className='float-end' />

    const map = (window.innerWidth > 991) ?
        <iframe title="Precinct Map" style={{ width: "100%", maxHeight: "100%", overflow: "hidden", overflowX: "hidden", overflowY: "hidden" }} height="600" width="1200" src="https://fni.maps.arcgis.com/apps/dashboards/ebe799ca5e734046b56a56d6cc0e2f60"></iframe>
        : <iframe title="Precinct Map" style={{ width: "100%", maxHeight: "100%", overflow: "hidden", overflowX: "hidden", overflowY: "hidden" }} height="600" width="1200" src="https://fni.maps.arcgis.com/apps/dashboards/ebe799ca5e734046b56a56d6cc0e2f60"></iframe>

    const ref = createRef();
    const { state } = useLocation()

    const sortByNumber = (data) => {
        data.sort((a, b) => {
            if (a.number === b.number) {
                a = a.title.toLowerCase()
                b = b.title.toLowerCase()
                return (a < b) ? -1 : (a > b) ? 1 : 0
            }
            a = a.number.toLowerCase()
            b = b.number.toLowerCase()
            return (a < b) ? -1 : (a > b) ? 1 : 0
        })
    }

    const sortByName = (data) => {
        data.sort((a, b) => {
            a = a.title.toLowerCase()
            b = b.title.toLowerCase()
            return (a < b) ? -1 : (a > b) ? 1 : 0
        })
    }

    const sortByNumberReverse = (data) => {
        data.sort((a, b) => {
            if (a.number === b.number) {
                a = a.title.toLowerCase()
                b = b.title.toLowerCase()
                return (a > b) ? -1 : (a < b) ? 1 : 0
            }
            a = a.number.toLowerCase()
            b = b.number.toLowerCase()
            return (a > b) ? -1 : (a < b) ? 1 : 0
        })
    }

    const sortByNameReverse = (data) => {
        data.sort((a, b) => {
            a = a.title.toLowerCase()
            b = b.title.toLowerCase()
            return (a > b) ? -1 : (a < b) ? 1 : 0
        })
    }
    const sortByPrecinct = (data) => {
        console.log(data);
        data.sort((a, b) => {
            a = a.precinct
            b = b.precinct
            return (a < b) ? -1 : (a > b) ? 1 : 0
        })
    }
    const sortByPrecinctReverse = (data) => {
        data.sort((a, b) => {
            a = a.precinct
            b = b.precinct
            return (a > b) ? -1 : (a < b) ? 1 : 0
        })
    }

    useEffect(
        () => {
            if (sortBy[0] === 'Name' && sortBy[1] === false) sortByName(ProjectsData)
            else if (sortBy[0] === 'Number' && sortBy[1] === false) sortByNumber(ProjectsData)
            else if (sortBy[0] === 'Precinct' && sortBy[1] === false) sortByPrecinct(ProjectsData)
            else if (sortBy[0] === 'Name' && sortBy[1] === true) sortByNameReverse(ProjectsData)
            else if (sortBy[0] === 'Number' && sortBy[1] === true) sortByNumberReverse(ProjectsData)
            else if (sortBy[0] === 'Precinct' && sortBy[1] === true) sortByPrecinctReverse(ProjectsData)

            var data = ProjectsData.map((d) => {
                return (<ProjectListItem title={d.title} id={d.id} number={d.number} key={d.id} precinct={d.precinct} precinct2={d.precinct2} />)
            })
            setProjectLinks(data)
        }, [sortBy]

    )

    useEffect(() => {
        if (state == null) return;
        if (state.fromProjects === false) return;
        window.scrollTo({ left: 0, top: ref.current.offsetTop, behavior: 'smooth' })
    }, [state, ref])

    const handleClick = (sort) => {
        if (sortBy[0] === sort) setSortBy([sortBy[0], !sortBy[1]])
        else if (sortBy[0] !== sort && sort === 'Name') setSortBy(['Name', false])
        else if (sortBy[0] !== sort && sort === 'Number') setSortBy(['Number', false])
        else if (sortBy[0] !== sort && sort === 'Precinct') setSortBy(['Precinct', false])
    }


    return (
        <div>
            <main className='flex-shrink-0 modular min-vh-100'>
                <div className="container py-4">
                    <div className="row mb-3">
                        <h1 className='mb-3'>Projects Map</h1>
                        <div className='col-12'>
                            <iframe title="Precinct Map" style={{ width: "100%", maxHeight: "100%", overflow: "hidden", overflowX: "hidden", overflowY: "hidden" }} height="600" width="1200" src="https://fni.maps.arcgis.com/apps/dashboards/ebe799ca5e734046b56a56d6cc0e2f60"></iframe>
                        </div>
                    </div>
                    <div className="row">
                        <h1>Projects</h1>
                        <table className='table table-striped table-hover mx-auto' style={{ width: "90%" }}>
                            <thead>
                                <tr>
                                    <th className="col-4 hover">
                                        <div className='table-button text-start' onClick={() => handleClick('Number')}>
                                            <h4>Project {sortBy[0] === 'Number' ? (sortBy[1] === false) ? arrowDown : arrowUp : ""}</h4>
                                        </div>
                                    </th>
                                    <th className="col-4 hover">
                                        <div className='table-button text-start' onClick={() => handleClick('Name')}>
                                            <h4>Name {sortBy[0] === 'Name' ? (sortBy[1] === false) ? arrowDown : arrowUp : ""}</h4>
                                        </div>
                                    </th>
                                    <th className="col-4 hover">
                                        <div className='table-button text-start' onClick={() => handleClick('Precinct')}>
                                            <h4>Precinct {sortBy[0] === 'Precinct' ? (sortBy[1] === false) ? arrowDown : arrowUp : ""}</h4>
                                        </div>
                                    </th>
                                </tr>
                            </thead>
                            <tbody style={{ cursor: "pointer" }} >
                                {projectLinks}
                            </tbody>
                        </table>
                    </div>
                </div>
            </main>
        </div>
    )
}